<template>
  <div>
    <el-dialog
      title="单词列表"
      :visible.sync="show"
      width="70%"
      :before-close="closeFn"
    >
      <!-- 筛选 -->
      <el-card style="margin-top: 15px">
        <el-row class="title_row">
          <el-col>
            <span class="title_class">条件筛选</span>
          </el-col>
        </el-row>
        <el-form ref="queryFormRef" :model="queryForm" label-width="80px">
          <el-row :gutter="50">
            <el-col :span="8">
              <el-form-item label="单词" prop="spell">
                <el-input
                  v-model="queryForm.spell"
                  placeholder="请输入"
                  clearable
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="词源" prop="languageOrigin">
                <el-input
                  v-model="queryForm.languageOrigin"
                  placeholder="请输入"
                  clearable
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="组别" prop="level">
                <el-select
                  placeholder="请选择"
                  v-model="queryForm.level"
                  style="width: 100%"
                >
                  <el-option
                    v-for="item in dict_Group"
                    :key="item.dictCode"
                    :label="item.dictLabel"
                    :value="item.dictValue"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="托福词汇" prop="istoefl">
                <el-select
                  placeholder="请选择"
                  v-model="queryForm.istoefl"
                  style="width: 100%"
                >
                  <el-option
                    v-for="item in dict_istoefl"
                    :key="item.dictCode"
                    :label="item.dictLabel"
                    :value="item.dictValue"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="音节" prop="syllables">
                <el-select
                  placeholder="请选择"
                  v-model="queryForm.syllables"
                  style="width: 100%"
                >
                  <el-option
                    v-for="item in dict_syllables"
                    :key="item.dictCode"
                    :label="item.dictLabel"
                    :value="item.dictValue"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
        <el-row :gutter="20" type="flex" justify="end">
          <el-col :span="3">
            <el-button type="primary" style="width: 100%" @click="searchFn()"
              >查询</el-button
            >
          </el-col>
          <el-col :span="3">
            <el-button type="primary" plain style="width: 100%" @click="reset"
              >重置</el-button
            >
          </el-col>
        </el-row>
      </el-card>
      <!-- 列表 -->
      <el-card style="margin-top: 15px">
        <el-row class="title_row">
          <el-col :span="5">
            <span class="title_class">单词列表</span>
          </el-col>
        </el-row>
        <el-table
          header-cell-class-name="tableHeaderStyle"
          :data="dataList"
          @selection-change="handleSelectionChange"
          :row-key="getRowKey"
          border
          stripe
        >
          <el-table-column
            type="selection"
            width="55"
            :reserve-selection="true"
          >
          </el-table-column>
          <el-table-column label="序号" type="index"></el-table-column>
          <el-table-column label="单词" prop="spell">
            <template slot-scope="scope">
              <el-link
                style="color: #409eff"
                @click="handleToWordVoice(scope.row.purl)"
                >{{ scope.row.spell }}</el-link
              >
            </template>
          </el-table-column>
          <el-table-column
            label="音标"
            prop="internationalPhoneticAlphabet"
          ></el-table-column>
          <el-table-column
            label="定义"
            prop="def"
            :show-overflow-tooltip="true"
          >
            <template slot-scope="scope">
              <el-link
                style="color: #409eff"
                @click="handleToWordVoice(scope.row.durl)"
                >{{ scope.row.def }}</el-link
              >
            </template></el-table-column
          >
          <el-table-column
            label="例句"
            prop="exp"
            :show-overflow-tooltip="true"
          >
            <template slot-scope="scope">
              <el-link
                style="color: #409eff"
                @click="handleToWordVoice(scope.row.eurl)"
                >{{ scope.row.exp }}</el-link
              >
            </template></el-table-column
          >
          <el-table-column
            label="组别"
            prop="level"
            :formatter="groupFormatter"
          ></el-table-column>
          <!-- <el-table-column label="词源" prop="languageOrigin"></el-table-column> -->
        </el-table>
        <!-- 分页 -->
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="queryForm.pageNum"
          :page-sizes="[10, 20, 40, 80]"
          :page-size="queryForm.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </el-card>
      <el-row type="flex" justify="center" style="margin-top: 20px">
        <el-col :span="4">
          <el-button
            type="primary"
            style="width: 100%"
            @click="saveWordListData"
            >保存</el-button
          >
        </el-col>
      </el-row>
    </el-dialog>
    <audio ref="audioPlayer" @ended="overAudio"></audio>
  </div>
</template>
<script>
import { getWordList, saveWordListData } from '@/http/api'
export default {
  name: 'wordGroupAdd',
  props: {
    show: Boolean
  },
  data () {
    return {
      total: 0,
      dataList: [],
      selectWordList: [],
      dict_istoefl: [
        {
          dictCode: 1,
          dictLabel: '是',
          dictValue: 1
        },
        {
          dictCode: 0,
          dictLabel: '否',
          dictValue: 0
        }
      ],
      queryForm: {
        pageNum: 1,
        pageSize: 10
      },
      dict_syllables: this.$userInfo.dict_syllables(),
      dict_thesaurus_flag: this.$userInfo.getDataList(this, 'thesaurus_flag').then(value => { this.dict_thesaurus_flag = value }),
      dict_season: this.$userInfo.getDataList(this, 't_season').then(value => { this.dict_season = value }),
      dict_Group: this.$userInfo.dict_group()
    }
  },
  created () {
    this.getWordList()
  },
  methods: {
    handleSizeChange (newSize) {
      this.queryForm.pageSize = newSize
      this.getWordList()
    },
    handleCurrentChange (newCurPage) {
      this.queryForm.pageNum = newCurPage
      this.getWordList()
    },
    // 获取单词列表
    getWordList () {
      getWordList(this.queryForm).then((res) => {
        this.dataList = res.data.list
        this.total = res.data.total
      }).catch((err) => {
        console.log('返回的err', err)
      })
    },
    // 重置
    reset () {
      this.$refs.queryFormRef.resetFields()
      this.searchFn()
    },
    // 查询
    searchFn () {
      this.queryForm.pageNum = 1
      this.queryForm.pageSize = 10
      this.getWordList()
    },
    // 多选选中事件
    handleSelectionChange (val) {
      var selectList = []
      for (let index = 0; index < val.length; index++) {
        const element = val[index]
        const item = {}
        item.id = element.id
        item.spell = element.spell
        selectList.push(item)
      }
      this.selectWordList = selectList
    },
    getRowKey (row) {
      return row.id
    },
    // 保存点击事件
    saveWordListData () {
      saveWordListData({ id: this.groupId, wordList: this.selectWordList }).then((res) => {
        this.$emit('submit')
      }).catch((err) => {
        console.log('返回的err', err)
      })
    },
    closeFn () {
      this.$emit('close')
    },
    // 单词点击事件
    handleToWordVoice (voiceUrl) {
      this.$refs.audioPlayer.src = voiceUrl
      this.$refs.audioPlayer.play()
    },
    overAudio () {
    },
    groupFormatter (data) {
      var temp = '未设置'
      for (const key in this.dict_Group) {
        if (Object.hasOwnProperty.call(this.dict_Group, key)) {
          const element = this.dict_Group[key]
          if (element.dictValue === data.level + '') {
            temp = element.dictLabel
          }
        }
      }
      return temp
    }
  }
}
</script>

<style scoped lang="less">
.add {
  font-size: 22px;
  color: #409eff;
  margin-left: 20px;
  cursor: pointer;
  position: relative;
  top: 4px;
}
</style>
