<template>
  <div>
    <el-breadcrumb separator="/">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>学术管理</el-breadcrumb-item>
      <el-breadcrumb-item>词库管理</el-breadcrumb-item>
      <el-breadcrumb-item>词库详情</el-breadcrumb-item>
    </el-breadcrumb>
    <el-page-header @back="goBack"></el-page-header>
    <!-- 筛选 -->
    <el-card style="margin-top: 15px">
      <el-row class="title_row">
        <el-col>
          <span class="title_class">条件筛选</span>
        </el-col>
      </el-row>
      <el-form ref="queryFormRef" :model="queryForm" label-width="80px">
        <el-row :gutter="50">
          <el-col :span="6">
            <el-form-item label="单词" prop="spell">
              <el-input
                v-model="queryForm.spell"
                placeholder="请输入"
                clearable
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="词源" prop="languageOrigin">
              <el-input
                v-model="queryForm.languageOrigin"
                placeholder="请输入"
                clearable
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="托福词汇" prop="istoefl">
              <el-select
                placeholder="请选择"
                v-model="queryForm.istoefl"
                style="width: 100%"
              >
                <el-option
                  v-for="item in dict_istoefl"
                  :key="item.dictCode"
                  :label="item.dictLabel"
                  :value="item.dictValue"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="音节" prop="syllables">
              <el-select
                placeholder="请选择"
                v-model="queryForm.syllables"
                style="width: 100%"
              >
                <el-option
                  v-for="item in dict_syllables"
                  :key="item.dictCode"
                  :label="item.dictLabel"
                  :value="item.dictValue"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="状态" prop="state">
              <el-select
                placeholder="请选择"
                v-model="queryForm.state"
                style="width: 100%"
              >
                <el-option
                  v-for="item in dict_wordStorageType"
                  :key="item.dictCode"
                  :label="item.dictLabel"
                  :value="item.dictValue"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <el-row :gutter="20" type="flex" justify="end">
        <el-col :span="2">
          <el-button type="primary" style="width: 100%" @click="searchFn()"
            >查询</el-button
          >
        </el-col>
        <el-col :span="2">
          <el-button type="primary" plain style="width: 100%" @click="reset"
            >重置</el-button
          >
        </el-col>
      </el-row>
    </el-card>
    <!-- 列表 -->
    <el-card style="margin-top: 15px">
      <el-row class="title_row">
        <el-col :span="5">
          <span class="title_class">单词管理</span>
        </el-col>
        <el-col :span="19" style="text-align: right">
          <el-button type="primary" @click="addWordClicked()"
            >新增单词+</el-button
          >
        </el-col>
      </el-row>
      <el-table
        header-cell-class-name="tableHeaderStyle"
        :data="dataList"
        border
        stripe
      >
        <el-table-column label="序号" type="index"></el-table-column>
        <el-table-column label="单词" prop="spell">
          <template slot-scope="scope">
            <el-link
              style="color: #409eff"
              @click="handleToWordVoice(scope.row.purl)"
              >{{ scope.row.spell }}</el-link
            >
          </template>
        </el-table-column>
        <el-table-column
          label="音标"
          prop="internationalPhoneticAlphabet"
        ></el-table-column>
        <el-table-column label="定义" prop="def" :show-overflow-tooltip="true">
          <template slot-scope="scope">
            <el-link
              style="color: #409eff"
              @click="handleToWordVoice(scope.row.durl)"
              >{{ scope.row.def }}</el-link
            >
          </template></el-table-column
        >
        <el-table-column label="例句" prop="exp" :show-overflow-tooltip="true">
          <template slot-scope="scope">
            <el-link
              style="color: #409eff"
              @click="handleToWordVoice(scope.row.eurl)"
              >{{ scope.row.exp }}</el-link
            >
          </template></el-table-column
        >
        <el-table-column
          label="组别"
          prop="enrollmentLevel"
          :formatter="groupFormatter"
        ></el-table-column>
        <el-table-column label="托福词汇" prop="istoefl">
          <template slot-scope="scope">
            <el-tag type="success" v-if="scope.row.istoefl === 1" size="mini"
              >是</el-tag
            >
            <el-tag type="danger" v-else size="mini">否</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="音节" prop="syllables">
          <template slot-scope="scope">
            <el-tag type="success" v-if="scope.row.syllables === 2" size="mini"
              >多音节</el-tag
            >
            <el-tag
              type="warning"
              v-else-if="scope.row.syllables === 1"
              size="mini"
              >单音节</el-tag
            >
            <span v-else size="mini">-</span>
          </template>
        </el-table-column>
        <el-table-column label="词源" prop="languageOrigin"></el-table-column>
        <el-table-column label="状态" prop="state">
          <template slot-scope="scope">
            <el-tag type="warning" v-if="scope.row.state === 2" size="mini"
              >停用</el-tag
            >
            <el-tag type="success" v-else size="mini">启用</el-tag>
          </template></el-table-column
        >
        <el-table-column label="操作" width="140px">
          <template slot-scope="scope">
            <el-button
              type="text"
              icon="el-icon-delete"
              size="small"
              style="color: #f56c6c"
              @click="outOfServiceDialog(scope.row)"
              >{{ scope.row.state === 2 ? "启用" : "停用" }}</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryForm.pageNum"
        :page-sizes="[10, 20, 40, 80]"
        :page-size="queryForm.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </el-card>
    <audio ref="audioPlayer" @ended="overAudio"></audio>
    <groupWordAdd
      :show="addWordDialogVisible"
      :groupId="this.$route.query.groupId"
      @close="closeFn"
      @submit="submitData"
    ></groupWordAdd>
  </div>
</template>
<script>
import { wordThesaurusList, updateWordThesaurusState } from '@/http/api'
import groupWordAdd from './components/word_group_addword'
export default {
  components: {
    groupWordAdd
  },
  data () {
    return {
      addWordDialogVisible: false,
      playVoiceUrl: '',
      dict_syllables: this.$userInfo.dict_syllables(),
      dict_wordStorageType: [
        {
          dictCode: 1,
          dictLabel: '启用',
          dictValue: true
        },
        {
          dictCode: 2,
          dictLabel: '停用',
          dictValue: false
        }
      ],
      dict_istoefl: [
        {
          dictCode: 1,
          dictLabel: '是',
          dictValue: 1
        },
        {
          dictCode: 0,
          dictLabel: '否',
          dictValue: 0
        }
      ],
      editWordDetailForm: {},
      dataList: [],
      total: 0,
      dict_Group: this.$userInfo.dict_group(),
      queryForm: {
        id: this.$route.query.groupId,
        pageNum: 1,
        pageSize: 10
      },
      editVisible: false,
      editTitle: ''
    }
  },
  created () {
    this.getWordList()
  },
  methods: {
    goBack () {
      this.$router.back()
    },
    // 获取单词列表
    getWordList () {
      wordThesaurusList(this.queryForm).then((res) => {
        this.dataList = res.data.list
        this.total = res.data.total
      }).catch((err) => {
        console.log('返回的err', err)
      })
    },
    handleSizeChange (newSize) {
      this.queryForm.pageSize = newSize
      this.getWordList()
    },
    handleCurrentChange (newCurPage) {
      this.queryForm.pageNum = newCurPage
      this.getWordList()
    },
    // 查询
    searchFn () {
      this.queryForm.pageNum = 1
      this.queryForm.pageSize = 10
      this.getWordList()
    },
    // 重置
    reset () {
      this.$refs.queryFormRef.resetFields()
      this.searchFn()
    },
    // 新增单词
    addWordClicked () {
      this.addWordDialogVisible = true
    },
    closeFn () {
      this.addWordDialogVisible = false
    },
    // 单词点击事件
    handleToWordVoice (voiceUrl) {
      this.$refs.audioPlayer.src = voiceUrl
      this.$refs.audioPlayer.play()
    },
    // 单词停用/启用
    outOfServiceDialog (wordData) {
      var formData = {
        thesaurusId: this.queryForm.id,
        wordId: wordData.id,
        state: wordData.state === 2 ? 1 : 2
      }
      updateWordThesaurusState(formData).then((res) => {
        this.getWordList()
      }).catch((err) => {
        console.log('返回的err', err)
      })
    },
    // 保存信息
    submitData () {
      this.closeFn()
      this.$message.success('保存成功')
      this.reset()
    },
    overAudio () {
    },
    groupFormatter (data) {
      var temp = '未设置'
      for (const key in this.dict_Group) {
        if (Object.hasOwnProperty.call(this.dict_Group, key)) {
          const element = this.dict_Group[key]
          if (element.dictValue === data.level + '') {
            temp = element.dictLabel
          }
        }
      }
      return temp
    }
  }
}
</script>
